import { BarcodeReader, CodeDetection, Configuration, SdkError, StrichSDK } from "@pixelverse/strichjs-sdk";

const devMode = window.location.hostname === 'localhost';
const apiUrl = devMode ? 'http://localhost:5000' : 'https://kmwkd2pf3a.eu-west-1.awsapprunner.com'

let theBarcodeReader: BarcodeReader | null = null;
let handlingCode = false;

enum Step {IDLE, SCANNING, EDIT_PASS, ADD_TO_WALLET}

let theStep = Step.IDLE;

// ?mock=aztec, ?mock=qr
const mockBarcodeType = new URLSearchParams(window.location.search).get('mock') ?? null;

// UI elements
const primaryButton = document.getElementById('primaryButton') as HTMLButtonElement;
const progressContainer = document.getElementById('progressContainer');
const headerIdle = document.getElementById('headerIdle');
const headerScanning = document.getElementById('headerScanning');
const headerEditPass = document.getElementById('headerEditPass');
const footer = document.getElementById('footer');

const addToWalletContainer = document.getElementById('addToWalletContainer');
const addToGoogleWalletLink = document.getElementById('downloadLinkGoogleWallet') as HTMLAnchorElement;
const addToAppleWalletLink = document.getElementById('downloadLinkAppleWallet') as HTMLAnchorElement;

const desktopHintDialog = document.getElementById('desktopHintDialog') as HTMLDialogElement;
const desktopHintOkButton = document.getElementById('desktopHintOkButton') as HTMLButtonElement;

const editPassSection = document.getElementById('editPassContainer');
const barcodeTypeLabel = document.getElementById('barcodeType') as HTMLSpanElement;
const barcodeValueLabel = document.getElementById('barcodeValue') as HTMLSpanElement;
const passNameInput = document.getElementById('passName') as HTMLInputElement;

// see: https://plausible.io/docs/custom-event-goals#trigger-custom-events-manually-with-a-javascript-function
// @ts-ignore
window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }

interface Pass {
    name: string;
    barcodeType: string;
    barcodeValue: string;
}

let thePass: Pass | null = null;

/**
 * Check if we are on a mobile browser, including tablets.
 */
function isSupportedBrowser(): boolean {
    // see: https://stackoverflow.com/a/11381730/1370154
    const regExps: RegExp[] = [
        /Android/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i
    ];
    const isSupported = regExps.some((re) => {
        return navigator.userAgent.match(re);
    });
    if (!isSupported) {
        console.warn(`This browser seems to be unsupported: ${navigator.userAgent}`);
    }

    // note: above regexp fails to detect iPads, which are reported as Mac now, so also check touch points
    if (!isSupported && navigator.maxTouchPoints && navigator.maxTouchPoints > 1) {
        return true;
    }
    return isSupported;
}

function showUIForStep(step: Step) {
    switch (step) {
        case Step.IDLE:
            headerIdle.style.display = 'block';
            headerScanning.style.display = 'none';
            headerEditPass.style.display = 'none';
            footer.style.display = 'flex';
            primaryButton.style.display = 'block';
            primaryButton.innerText = 'Scan Barcode';
            headerIdle.style.display = 'block';
            addToWalletContainer.style.display = 'none';
            editPassSection.style.display = 'none';
            break;
        case Step.SCANNING:
            headerIdle.style.display = 'none';
            headerScanning.style.display = 'block';
            headerEditPass.style.display = 'none';
            footer.style.display = 'none';
            primaryButton.style.display = 'block';
            primaryButton.innerText = 'Stop Scanning';
            addToWalletContainer.style.display = 'none';
            editPassSection.style.display = 'none';
            break;
        case Step.EDIT_PASS:
            headerIdle.style.display = 'none';
            headerScanning.style.display = 'none';
            headerEditPass.style.display = 'block';
            footer.style.display = 'none';
            primaryButton.style.display = 'block';
            primaryButton.innerText = 'Create Pass';
            addToWalletContainer.style.display = 'none';
            editPassSection.style.display = 'block';
            passNameInput.focus();
            break;
        case Step.ADD_TO_WALLET:
            headerIdle.style.display = 'block';
            headerScanning.style.display = 'none';
            headerEditPass.style.display = 'none';
            footer.style.display = 'none';
            primaryButton.style.display = 'block';
            primaryButton.innerText = 'Scan Barcode';
            editPassSection.style.display = 'none';
            addToWalletContainer.style.display = 'flex';
            addToAppleWalletLink.style.display = 'none';
            addToGoogleWalletLink.style.display = 'none';
            const encName = encodeURIComponent(thePass.name);
            const encValue = encodeURIComponent(thePass.barcodeValue);
            if (isIOS()) {
                addToAppleWalletLink.href = `${apiUrl}/api/v1/apple/pass?type=generic&name=${encName}&barcodeType=${thePass.barcodeType}&value=${encValue}`;
                addToAppleWalletLink.style.display = 'block';
            } else {
                addToGoogleWalletLink.href = `${apiUrl}/api/v1/google/pass?type=generic&name=${encName}&barcodeType=${thePass.barcodeType}&value=${encValue}`;
                addToGoogleWalletLink.style.display = 'block';
            }
            break;
    }
    theStep = step;
}

function logEventOnScan(typeName: string) {
    if (devMode) {
        return;
    }
    const plausibleEvent = isIOS() ? 'ScanToAppleWallet' : 'ScanToGoogleWallet';
    // @ts-ignore
    plausible(plausibleEvent, {
        props: {
            typeName: typeName,
        }
    });
}

function handleDetectedCode(codeDetection: CodeDetection) {
    logEventOnScan(codeDetection.typeName);
    theBarcodeReader.stop().then(() => {
        theBarcodeReader.destroy().then(() => {
            theBarcodeReader = null;

            preparePassEditor(codeDetection.typeName, codeDetection.data);
            showUIForStep(Step.EDIT_PASS);
        });
    });
}

function preparePassEditor(barcodeType: string, barcodeValue: string) {
    thePass = {
        name: 'Generic Pass',
        barcodeType,
        barcodeValue
    }
    barcodeTypeLabel.innerText = barcodeType;
    barcodeValueLabel.innerText = barcodeValue;
}

function isIOS(): boolean {
    return (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod', 'MacIntel'].indexOf(navigator.platform) !== -1)
            // iPad on iOS 13 detection
            || ((navigator.userAgent.indexOf('Mac') !== -1) && 'ontouchend' in document);
}

// show hint regarding desktop usage if we're not on a supported browser
if (!isSupportedBrowser() && mockBarcodeType === null) {
    desktopHintDialog.showModal();
    desktopHintOkButton.onclick = () => {
        desktopHintDialog.close();
    }
}

showUIForStep(Step.IDLE);

primaryButton.onclick = async () => {

    if (theStep === Step.IDLE || theStep === Step.ADD_TO_WALLET) {

        if (mockBarcodeType !== null) {
            preparePassEditor(mockBarcodeType, 'THIS-IS-JUST-A-TEST');
            showUIForStep(Step.EDIT_PASS);
            return;
        }

        // no BarcodeReader, initialize and start scanning
        const config: Configuration = {
            selector: '#barcodeReader',
            frameSource: {
                resolution: 'hd'
            },
            locator: {
                regionOfInterest: {
                    left: 0.1, right: 0.1, top: 0.2, bottom: 0.2
                },
                chromaReject: false
            },
            engine: {
                symbologies: ['code128', 'qr', 'aztec', 'pdf417']
            },
            overlay: {
                showDetections: true,
                showFlashlight: true,
                showTargetingLine: true,
                showCameraSelector: true
            },
            feedback: {
                audio: true,
                vibration: true
            }
        };
        try {

            // show progress bar instead of button while camera is initializing
            primaryButton.style.display = 'none';
            progressContainer.style.display = 'block';

            const barcodeReader = new BarcodeReader(config);
            await barcodeReader.initialize();
            barcodeReader.detected = (detections) => {
                if (handlingCode || detections.length === 0) {
                    return;
                }
                handlingCode = true;
                handleDetectedCode(detections[0]);
            };
            theBarcodeReader = barcodeReader;
            await theBarcodeReader.start();

            showUIForStep(Step.SCANNING);
        } catch (e) {
            let errorMessage = 'An error occurred: ' + e.name;
            if (e instanceof SdkError) {
                errorMessage = e.localizedMessage;
            }
            console.error(e);
            window.alert(errorMessage.toString()); // FIXME: nicer error display
        } finally {
            progressContainer.style.display = 'none';
        }

    } else if (theStep === Step.SCANNING) {

        // BarcodeReader already exists, stop() an destroy()
        try {
            await theBarcodeReader.stop();
            await theBarcodeReader.destroy();
            theBarcodeReader = null;
            showUIForStep(Step.IDLE);
        } catch (e) {
            let errorMessage = 'An error occurred: ' + e.name;
            if (e instanceof SdkError) {
                errorMessage = e.localizedMessage;
            }
            window.alert(errorMessage); // FIXME: nicer error display
        }
        return;

    } else if (theStep === Step.EDIT_PASS) {

        // create pass
        thePass.name = passNameInput.value.trim();
        if (thePass.name.length === 0) {
            thePass.name = 'Generic Pass';
        }

        showUIForStep(Step.ADD_TO_WALLET);

    } else if (theStep === Step.ADD_TO_WALLET) {
        thePass = null;
        handlingCode = false;
        showUIForStep(Step.SCANNING);
    }
};

passNameInput.onkeydown = (evt) => {
    if (evt.key === 'Enter') {
        evt.preventDefault();
        primaryButton.click();
    }
};

const licenseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMmFkZTQ0Ny1jMzI2LTRmMmUtYjFmNS04MWY4Nzc4MjczZmUiLCJpc3MiOiJzdHJpY2guaW8iLCJhdWQiOlsiaHR0cHM6Ly9zdHJpY2guaW8vc2NhbnRvd2FsbGV0IiwiaHR0cHM6Ly9uZ3Jvay5waXhlbHZlcnNlLmNoIl0sImlhdCI6MTcwNTE1NTg4MCwibmJmIjoxNzA1MTU1ODgwLCJjYXBhYmlsaXRpZXMiOnsib2ZmbGluZSI6ZmFsc2UsImFuYWx5dGljc09wdE91dCI6ZmFsc2UsImN1c3RvbU92ZXJsYXlMb2dvIjpmYWxzZX0sInZlcnNpb24iOjF9.2er35kUOARBCxBXwDu_r0eCE5M6D83IkOqGiOTZmFYs';
StrichSDK.initialize(licenseKey)
    .then(() => {
        primaryButton.disabled = false;
    })
    .catch((err: SdkError) => {
        window.alert(err.localizedMessage);
    });
